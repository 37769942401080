import * as React from "react"
import Layout from "../components/Layout";
import localize from "../components/localize";
import {graphql} from "gatsby";
import SEO from "../components/SEO";
import Div100vh from "react-div-100vh";
import Job from "../components/Job";

export const query = graphql`
    {
    site {
    siteMetadata {
    lang{
        _type
        en
        de
        }
        angebot{
        _type
        en
        de
        }
        kunden{
        _type
        en
        de
        }
        impressum{
        _type
        en
        de
        }
        datenschutz{
        _type
        en
        de
        }
        agbs{
        _type
        en
        de
        }
        telefon{
        _type
        en
        de
        }
        startseite{
        _type
        en
        de
        }
        offeneJobs{
        _type
        en
        de
        }
        keineJobs{
        _type
        en
        de
        }
        mehrInfo{
        _type
        en
        de
        }
    }
    }
    allSanityJobs {
    edges {
      node {
        title{
            _type
            en
            de
        }
        slug{
            current
        }
        link
        _rawDescription
    }
  }
  }
  allSanitySettings {
    edges {
      node {
      standorte {
          street
          plz
          _rawStadt
          _rawName
        }
        telefon
        skype
        email
        linkedin
        jobsexternal
        jobsexternallink
     }
     }
    }
    allSanityNetzwerk {
    edges {
      node {
      title{
      en
      de
      _type
      }
      }
      }
      }
    }
   `;

const JobsPage = ({data, location}) => {

    const page = data.allSanityJobs.edges;

    const settings = data.allSanitySettings.edges[0].node;
    const netzwerk = data.allSanityNetzwerk.edges[0].node;

    return (
        <div id={"top"}>
            <Layout location={location} metadata={data.site.siteMetadata} settings={settings} netzwerk={netzwerk.title}>
                <SEO title={"Jobs | Dr. Barbara Stampf"}/>
                <div className={"jobs__outer"}>
                    <div className="color-bg bg-green left2"></div>
                    <div className="color-bg bg-orange right2"></div>
                    <div className="jobs__inner">
                        <h1 className={"uppercase color-light"}>{data.site.siteMetadata.offeneJobs}</h1>
                        {page ?
                            page.map(el => {
                                return <Job job={el.node} info={data.site.siteMetadata.mehrInfo}></Job>
                            })
                            :
                            <h2 className={"bold nojobs"}>{data.site.siteMetadata.keineJobs}</h2>
                        }
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default localize(JobsPage)
